
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/assets/poweredbyonchain.png"  alt="logo" height="200px" />
       
      </header>
    </div>
  );
}

export default App;
